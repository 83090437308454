import React from "react"
import solutionsData from "../../../components/data/solutions"
import { Intent } from "../../../components/common/intent"
import orangeArrowImage from "../../../components/assets/img/ic-orange-arrow.png"

const SolutionList = () => (
    <section className="solution-list">
        <h1 className="title medium">
            CONHEÇA <span className="medium">NOSSAS SOLUÇÕES</span>
        </h1>
        <div className="wrapper">
            {solutionsData.map((item, index) => (
                <figure key={index} className="item">
                    <img className="image" src={item.IMG} alt={item.Name} />
                    <div className="content">
                        <div className="flex-box">
                            <img
                                className="arrow"
                                src={orangeArrowImage}
                                alt="arrow"
                            />
                            <span className="subtitle medium">
                                {item.Name.toUpperCase()}
                            </span>
                        </div>
                        <p className="text regular">{item.Description}</p>
                        <Intent classes="button" to={`/solucoes/${item.Slug}`}>
                            <span className="bold">Saiba Mais</span>
                        </Intent>
                    </div>
                </figure>
            ))}
        </div>
    </section>
)

export default SolutionList
