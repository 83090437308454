import acessoImage from "../assets/img/controle-de-acesso.jpg"
import expedicaoImage from "../assets/img/controle-de-expedicao.jpg"
import retornaveisImage from "../assets/img/controle-de-itens-retornaveis.jpg"
import movimentacaoImage from "../assets/img/controle-de-movimentacao.jpg"
import pneusImage from "../assets/img/gestao-total-de-pneus.jpg"
import autenticidadeImage from "../assets/img/garantia-de-autenticidade.jpg"
import realImage from "../assets/img/inventario-em-tempo-real.jpg"
import industrialImage from "../assets/img/lavanderia-industrial.jpg"
import frotasImage from "../assets/img/logistica-gestao-de-frotas.jpg"
import rastreabilidadeImage from "../assets/img/rastreabilidade.jpg"
import mineracaoImage from "../assets/img/ferrovia-e-mineracao.jpg"
import sensorImage from "../assets/img/smart-sensor.jpg"

const solutionsData = [
    {
        IMG: acessoImage,
        Name: "Controle de Acesso",
        Description:
            "Solução em controle de acesso de colaboradores em Empresas e moradores em Condomínios utilizando Tecnologia RFID Ativa e Passiva",
        Slug: "controle-de-acesso",
    },
    {
        IMG: expedicaoImage,
        Name: "Controle de Expedição",
        Description:
            "Com a utilização de portais eletrônicos utilizando Tecnologia RFID é possível saber de fato o que realmente embarcou no veículo.",
        Slug: "controle-de-expedicao",
    },
    {
        IMG: retornaveisImage,
        Name: "Controle de Itens Retornáveis",
        Description:
            "Controle de Pallets, Barris, Cilindros, Gaiolas Metálicas, Gaiolas Industriais, Caixas Plásticas e muitos outros itens retornáveis.",
        Slug: "controle-de-itens-retornaveis",
    },
    {
        IMG: movimentacaoImage,
        Name: "Controle de Movimentação",
        Description:
            "Controle de Movimentação de itens e produtos nos processos fabris e logísticos.",
        Slug: "controle-de-movimentacao",
    },
    {
        IMG: pneusImage,
        Name: "Gestão Total de Pneus",
        Description:
            "Utilize nossa Solução RFID e aumente a vida útil dos pneus de sua frota em até 25% e tenha até 3% de economia de combustível",
        Slug: "gestao-total-de-pneus",
    },
    {
        IMG: autenticidadeImage,
        Name: "Garantia de Autenticidade",
        Description:
            "Saiba se seu produto é legítimo. Utilize RFID e não deixe seu lucro nas mãos da pirataria!",
        Slug: "garantia-de-autenticidade",
    },
    {
        IMG: realImage,
        Name: "Inventário em Tempo Real",
        Description:
            "Tenha o seu inventário em tempo real. Utilize RFID em seus itens e saiba de modo online o que se passa em seus estoques.",
        Slug: "inventario-em-tempo-real",
    },
    {
        IMG: industrialImage,
        Name: "Lavanderia Industrial",
        Description:
            "Maior agilidade e qualidade nos processos de Embarque, Coleta e Entrega.",
        Slug: "lavanderia-industrial",
    },
    {
        IMG: frotasImage,
        Name: "Logística - Gestão de Frotas",
        Description:
            "A SOWX, empresa especializada em solução de inovação tecnológica, atua com tecnologia RFID no mercado de logística com soluções para gestão de  pneus e frotas.",
        Slug: "logistica-gestao-de-frotas",
    },
    {
        IMG: rastreabilidadeImage,
        Name: "Rastreabilidade",
        Description:
            "Tenha Rastreabilidade total de seus itens ou produtos. Utilize RFID e acompanhe em tempo real.",
        Slug: "rastreabilidade",
    },
    {
        IMG: mineracaoImage,
        Name: "Ferrovia e Mineração",
        Description:
            "Sistemas Avançados e Tecnologia RFID aplicado em ativos e componentes vagões.",
        Slug: "ferrovia-e-mineracao",
    },
    {
        IMG: sensorImage,
        Name: "Smart Sensor",
        Description:
            "Sensor de monitoramento de vibração e operação de máquinas.",
        Slug: "smart-sensor",
    },
]

export default solutionsData
